<template>
  <div>
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="box_top"
    >
      <!-- 标题 -->
      <h4 style="margin: 15px 65px">
        {{ titlename }} {{ titletype }}· {{ VenueType }}
        <!-- 新增轮次/新增周 -->
        <el-button
          v-if="type === 'qualifier' || type === 'regular'"
          type="primary"
          size="large"
          @click="Onaddweek"
          class="button_type"
          >{{ newlyAdded }}</el-button
        >
      </h4>

      <!-- 每场比赛持续时间 -->
      <el-form-item
        id="duration"
        label-width="200px"
        label="每场比赛持续时间"
        prop="duration"
        style="margin-bottom: 18px"
      >
        <el-col :span="3">
          <el-input
            ref="duration"
            type="number"
            v-model="dataForm.duration"
            placeholder="请输入每场比赛持续时间"
            ><template slot="append">分钟</template></el-input
          >
        </el-col>
        
        <el-button
          v-if="
            (type === 'qualifier' || type === 'regular') &&
              match_type.includes('match_s')
          "
          size="large"
           @click="Onsettlement"
          class="button_type2"
          >结算</el-button
        >
      </el-form-item>
      <!-- tab 预选赛-->
      <el-tabs
        v-if="type === 'qualifier'"
        v-model="activeWeek"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in weeks"
          :label="item.label"
          :name="`${item.name}`"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <!-- tab 常规赛-->
      <el-tabs
        v-if="type === 'regular' && match_type.includes('match_s')"
        v-model="activeWeek"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in weeks_regular"
          :label="item.label"
          :name="`${item.name}`"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <!-- 周 -->
      <el-form-item
        v-if="type === 'qualifier' && match_type.includes('match_s')"
        label="周"
        prop="type"
      >
        <div>
          {{ stage_name }}
        </div>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item
        v-if="type === 'regular' && match_type.includes('match_s')"
        label="名称"
        prop="type"
      >
        <div>
          {{ stage_name_regular }}
        </div>
      </el-form-item>
      <!-- 赛前分组时间 -->
      <el-form-item
        v-if="match_type.includes('match_s') && type != 'final'"
        label="赛前分组时间"
        :required="dataForm.type_id != 'wpl_finals'"
        label-width="200px"
      >
        <el-col :span="5">
          <el-form-item prop="match_review_start" id="match_time.pre_startTime">
            <el-date-picker
              ref="pre_startTime"
              type="datetime"
              placeholder="选择赛前分组开始时间"
              v-model="dataForm.match_review_start"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="pre_endTime">
          <el-form-item id="match_time.pre_endTime" prop="match_review_end">
            <el-date-picker
              ref="pre_endTime"
              type="datetime"
              placeholder="选择赛前分组结束时间"
              v-model="dataForm.match_review_end"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!--分组类型，预选赛 -->
      <el-form-item
        v-if="type === 'qualifier' && match_type.includes('match_s')"
        label="分组类型"
        prop="group_type"
      >
        <el-select v-model="dataForm.group_type" :style="{ width: '220px' }">
          <!-- 下拉框选项 -->
          <el-option label="随机分组" value="1"></el-option>
          <el-option label="积分排序分组" value="2"></el-option>
        </el-select>
      </el-form-item>
      <!--分组类型，常规赛 -->
      <el-form-item
        v-if="type === 'regular' && match_type.includes('match_s')"
        label="分组类型"
        prop="group_type"
      >
        <el-select v-model="dataForm.group_type" :style="{ width: '220px' }">
          <!-- 下拉框选项 -->
          <el-option label="随机分组" value="1"></el-option>
          <el-option label="两组偶数互换" value="3"></el-option>
        </el-select>
      </el-form-item>
      <!-- 选择场次生成时间 -->
      <el-form-item
        label="选择场次生成时间"
        id="start_time_stage"
        prop="start_time_stage"
      >
        <el-col :span="10">
          <el-date-picker
            ref="start_time_stage"
            type="dates"
            placeholder="选择场次生成时间"
            v-model="dataForm.start_time_stage"
            style="width: 100%"
            @change="stichArrayTimes"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <!-- 场次生成日期 -->
      <el-form-item
        v-if="
          dataForm.start_time_stage && dataForm.start_time_stage.length != 0
        "
        id="start_date"
        label-width="200px"
        label="场次生成日期"
        prop="start_date"
        style="margin-bottom: 35px"
      >
        <el-row v-for="(item, index) in switch_time_stage" :key="index">
          <!-- 当天比赛场数 -->
          <el-col :span="24">
            <el-col
              :span="1.2"
              :style="{
                border: ' 1px solid rgb(220, 223, 230)',
                backgroundColor: '#FCFCFC',
                borderRadius: '5px',
                width: '150px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            >
              {{ item.start_date }}
            </el-col>
            <!-- 当天比赛场数 -->
            <el-col :span="6">
              <el-form-item
                v-if="type != 'qualifier'"
                label="当天比赛场数"
                :prop="`sessions[${index}].dates_num`"
                :rules="rules.dates_num"
              >
                <el-select
                  v-model="item.dates_num"
                  @change="
                    getDetailNum(item, item.dates_num, item.match_detail)
                  "
                >
                  <!-- <el-option :value="1" :label="'1场'"></el-option>
                  <el-option :value="2" :label="'2场'"></el-option> -->
                  <!-- sessionsNum -->
                  <el-option
                    v-for="(item, index) in sessionsNum"
                    :key="index"
                    :label="`${item}场`"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <!-- 场次循环模板 -->
          <el-col
            :span="24"
            v-for="(itemOne, idx) in item.match_detail"
            :key="idx"
          >
            <div class="xunhuan">
              <!-- 第几场的开始时间 -->
              <span>
                <el-form-item
                  :label="`第${itemOne.time_num}场开始时间`"
                  :prop="`sessions[${index}].match_detail[${idx}].start_time`"
                  :rules="rules.start_time"
                >
                  <el-time-picker
                    v-model="itemOne.start_time"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59',
                    }"
                    value-format="HH:mm:ss"
                    placeholder="默认场次生成时间"
                  >
                  </el-time-picker>
                </el-form-item>
              </span>
              <!-- 对战组 1-->
              <span
                v-if="match_type === 'match_s3' && pageName == '常规赛'"
                class="form-row"
              >
                <el-form-item
                  label="对战组"
                  :prop="`sessions[${index}].match_detail[${idx}].group_ids1`"
                  :rules="rules.zu"
                >
                  <el-select
                    style="width: 100px"
                    placeholder=""
                    v-model="itemOne.group_ids1"
                  >
                    <el-option
                      v-for="(item, index) in OppositeStationGroup.group"
                      :key="index"
                      :label="`第${item.group_name}`"
                      :value="item._id"
                    ></el-option>
                  </el-select>
                  <span>&nbsp;VS&nbsp;</span>
                </el-form-item>
                <el-form-item
                  label=""
                  :prop="`sessions[${index}].match_detail[${idx}].group_ids2`"
                  :rules="rules.zu"
                >
                  <el-select
                    style="width: 100px"
                    placeholder=""
                    v-model="itemOne.group_ids2"
                  >
                    <el-option
                      v-for="(item, index) in OppositeStationGroup.group"
                      :key="index"
                      :label="`第${item.group_name}`"
                      :value="item._id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </span>
              <span>
                <el-form-item
                  label="版型"
                  :prop="`sessions[${index}].match_detail[${idx}].pattern`"
                  :rules="rules.pattern"
                >
                  <el-input
                    ref="season_name"
                    v-model="itemOne.pattern"
                    maxlength="12"
                    placeholder="请输入版型名称"
                  ></el-input>
                </el-form-item>
              </span>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 删除，结算 -->
      <el-form-item
        v-if="
          (type === 'qualifier' || type === 'regular') &&
            match_type.includes('match_s')
        "
        style="margin-top: 50px"
      >
        <el-button
          v-if="Delete_button_display"
          size="large"
          type="primary"
          @click="DeleteWeek"
          >{{ delname }}</el-button
        >
        <el-button
          size="large"
          type="primary"
          @click="save"
          class="buton_type"
          >保存</el-button
        >
      </el-form-item>
      <!-- 提交/取消 -->
      <el-form-item
        v-if="match_type != 'match_s4' || type === 'final'"
        style="margin-top: 50px"
        class="buton_type2"
      >
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          v-loading="loading"
           class="buton_type"
          >提交</perButton
        >
        <el-button size="large" class="buton_type" @click="goBack"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 新增周dialog -->
    <el-dialog :visible.sync="showDialog" title="新增周" width="30%" center>
      <el-form ref="addweek" :model="addweek" class="addweek">
        <div class="centeer">
          <el-form-item
            v-if="type === 'qualifier'"
            label="名称"
            prop="label"
            class="addname"
            :rules="[{ required: true, message: '请选择名称' }]"
          >
            <el-select v-model="addweek.label" :style="{ width: '220px' }">
              <!-- 下拉框选项 -->
              <el-option label="第1周" value="1"></el-option>
              <el-option
                v-for="n in Math.min(weeks.length, 10)"
                :key="n"
                :label="`第${n + 1}周`"
                :value="`第${n + 1}周`"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="分组类型"
            prop="group_type"
            id="group_type"
            :rules="[{ required: true, message: '请选择分组类型' }]"
          >
            <el-select v-model="addweek.group_type" :style="{ width: '220px' }">
              <!-- 下拉框选项 -->
              <el-option label="随机分组" value="1"></el-option>
              <el-option label="积分排序分组" value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveWeek">保存</el-button>
      </div>
    </el-dialog>
    <!-- 新增伦次dialog -->
    <el-dialog
      :visible.sync="showDialogcoherence"
      title="新增场次"
      width="30%"
      center
    >
      <el-form ref="addcoherence" :model="addcoherence" class="addcoherence">
        <div class="centeer">
          <el-form-item
            label="名称"
            prop="label"
            class="addname"
            :rules="[{ required: true, message: '请选择名称' }]"
          >
            <el-select v-model="addcoherence.label" :style="{ width: '220px' }">
              <!-- 下拉框选项 -->
              <el-option
                label="换组积分赛(第1轮)"
                value="换组积分赛(第1轮)"
              ></el-option>
              <el-option
                v-for="n in Math.min(weeks_regular.length, 10)"
                :key="n"
                :label="`换组积分赛(第${n + 1}轮)`"
                :value="`换组积分赛(第${n + 1}轮)`"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="分组类型"
            prop="group_type"
            id="group_type"
            :rules="[{ required: true, message: '请选择分组类型' }]"
          >
            <el-select
              v-model="addcoherence.group_type"
              :style="{ width: '220px' }"
            >
              <!-- 下拉框选项 -->
              <el-option label="随机分组" value="1"></el-option>
              <el-option label="两组偶数互换" value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onAddCoherence">保存</el-button>
      </div>
    </el-dialog>
    <!-- 新增伦次 -->
    <!-- 删除周 -->
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span v-if="type === 'qualifier'"
        >你确定要删除{{ weeks[this.activeWeek - 1].label }}的场次内容吗</span
      >
      <span v-if="type === 'regular'"
        >你确定要删除{{
          weeks_regular[this.activeWeek - 1].label
        }}的场次内容吗</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisibleadd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { randomStr } from '@/utils/randomStr'
import { MessageBox } from 'element-ui'
import perButton from '@/components/perm/perButton'
import { matchTypeMap, matchType } from './public.js/toolbarOptions'
export default {
  name: 's1_banners',
  computed: {
    ...mapState('match', ['match_types']),
    seasonName() {
      if (this.match_type.includes('match')) {
        return '门派联赛第五赛季'
      }
      return '门派联赛'
    },
    titletype() {
      return matchType[this.type] || ''
    },
    titlename() {
      return matchTypeMap[this.match_type] || ''
    },
    VenueType() {
      if (this.dataForm.duration === null) {
        return '生成场次'
      } else {
        return '编辑场次'
      }
    },
    switch_time_stage() {
      // 检查 this.dataForm.sessions 是否存在
      if (!this.dataForm.sessions) {
        // 如果不存在，返回一个空数组
        return []
      }

      // 按照时间大小排序
      return this.dataForm.sessions.slice().sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date)
      })
    },

    //计算tab（预选赛）
    stage_name() {
      const weeks = ['第一周', '第二周', '第三周', '第四周', '第五周']
      return weeks[this.activeWeek - 1]
    },
    //计算tab（常规赛）
    stage_name_regular() {
      const weeks = [
        '换组积分赛第1轮',
        '换组积分赛第2轮',
        '换组积分赛第3轮',
        '换组积分赛第4轮',
        '换组积分赛第5轮',
        '换组积分赛第6轮',
        '换组积分赛第7轮',
      ]
      return weeks[this.activeWeek - 1]
    },
    newlyAdded() {
      if (this.type === 'qualifier') {
        return '新增周'
      } else if (this.type === 'regular') {
        return '新增场次'
      } else {
        return ''
      }
    },
    delname() {
      if (this.type === 'qualifier') {
        return '删除周'
      } else if (this.type === 'regular') {
        return '删除场次'
      } else {
        return ''
      }
    },
  Delete_button_display() {
    if (this.Content_tab && this.Content_tab.length >= this.activeWeek && this.activeWeek) {
        let button_type = this.Content_tab[this.activeWeek - 1].status;
        return !(button_type === 2 || button_type === 1);
    } else {
        return true;
    }
}

  },
  components: {
    perButton,
  },
  watch: {
    activeWeek: function(newValue, oldValue) {
      console.log(`tab从 "${oldValue}" 更改为 "${newValue}"`)
    },
  },
  data() {
    let years = []
    for (
      let i = new Date().getFullYear() + 1;
      i >= new Date().getFullYear() - 10;
      i--
    ) {
      years.push(i)
    }

    let self = this

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.start_time != null && self.dataForm.end_time != null) {
        let match_start = new Date(self.dataForm.start_time)
        let match_end = new Date(self.dataForm.end_time)
        if (match_end <= match_start) {
          callback(new Error('结束时间必须大于开始时间'))
          return
        }
      }
      callback()
    }

    return {
      Content_tab: '',
      weeks: [{ label: '第1周', name: '1' }],
      activeWeek: '1', // 默认选中第一周
      weeks_regular: [{ label: '换组积分赛(第1轮)', name: '1' }],
      OppositeStationGroup: [],
      match_type: '', //赛季type
      pageName: '',
      pager: {
        total: 10,
        pageNum: 1,
      },
      sessionsNum: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      ids: [],
      match_id: '',
      loading: false,
      searchForm: {},
      dataTable: [],
      bannerVisible: false,
      bannerData: {
        view_type: 'add',
        _id: '',
        sort: '',
        banner_url: '',
      },
      match: false,
      view_type: 'edit',
      type: 's1',
      years: years,
      has_stage: false,
      org_rule: '',
      org_prize: '',

      dataForm: {
        match_review_start: '',
        match_review_end: '',
        sessions: [],
        start_time_stage: [],
        _id: '',
        match_id: '',
        duration: '',
        season_name: '',
        end_time: '',
        start_time: '',
        group_type: '', //分组类型
      },
      addweek: {
        label: '',
        group_type: '',
        name: '',
      },
      addcoherence: {
        label: '',
        group_type: '',
        name: '',
      },
      centerDialogVisible: false,
      showDialog: false,
      showDialogcoherence: false,
      rules: {
        match_review_start: [
          {
            required: true,
            message: '请选择赛前分组开始时间',
            trigger: 'blur',
          },
        ],
        match_review_end: [
          {
            required: true,
            message: '请选择赛前分组结束时间',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (
                value &&
                this.dataForm.match_review_start &&
                value < this.dataForm.match_review_start
              ) {
                callback(new Error('结束时间必须大于开始时间'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        zu: [
          {
            required: true,
            message: '请输入对战组',
            trigger: ['blur'],
          },
        ],
        duration: [
          {
            required: true,
            message: '请输入每场比赛持续时间',
            trigger: ['blur'],
          },
        ],
        start_time_stage: [
          {
            type: 'array',
            required: true,
            message: '请选择场次生成时间',
            trigger: ['change'],
          },
        ],
        start_time: [
          {
            required: true,
            message: '请选择场次生成时间',
            trigger: ['change'],
          },
        ],

        dates_num: [
          {
            required: true,
            message: '请选择当天比赛场数',
            trigger: ['change', 'blur'],
          },
        ],
        group_type: [
          {
            required: true,
            message: '请输选择分组类型',
            trigger: ['blur'],
          },
        ],
        pattern: [
          {
            required: true,
            message: '请输入版型名称',
            trigger: ['blur'],
          },
        ],
      },
    }
  },
  methods: {
    // 获取tab及其数据
    async get_stage_list() {
      const res = await this.$http.match.s4_stage_list({
        match_id: this.$route.query.match_id,
        season_id: this.$route.query._id,
        pageNum: '1',
        pageSize: '10',
      })
      //增加tab
      if (this.weeks_regular.length < res.data.data.length) {
        for (let i = 1; i < res.data.data.length; i++) {
          this.weeks_regular.push({
            label: `换组积分赛第${i + 1}轮`,
            name: `${i + 1}`,
          })
        }
      }
      if (this.weeks.length < res.data.data.length) {
        for (let i = 1; i < res.data.data.length; i++) {
          this.weeks.push({ label: `第${i + 1}周`, name: `${i + 1}` })
        }
      }
      console.log(
        '🚀 ~ get_stage_list ~ res.data.data.length:',
        res.data.data.length
      )

      let weekINDEX = this.activeWeek - 1
      console.log('🚀 ~ get_stage_list ~ weekINDEX:', weekINDEX)
      if (weekINDEX >= res.data.data.length) {
        this.dataForm = {
          match_review_start: '',
          match_review_end: '',
          sessions: [],
          start_time_stage: [],
          _id: '',
          match_id: '',
          duration: '',
          season_name: '',
          end_time: '',
          start_time: '',
          group_type: '', //分组类型
        }
        return
      }
      // 数据展示
      const dataIndex = parseInt(this.activeWeek, 10) - 1 // 将activeWeek转换为数组索引
      const dataLength = res.data.data.length //内容长度
      if (dataLength >= Number(this.activeWeek)) {
        this.dataForm.duration = res.data.data[dataIndex].duration
        this.$set(
          this.dataForm,
          'group_type',
          res.data.data[dataIndex].group_type.toString()
        )
        if (
          res.data.data[dataIndex].match_review_start &&
          res.data.data[dataIndex].match_review_start.length > 0
        ) {
          this.$set(
            this.dataForm,
            'match_review_start',
            new Date(res.data.data[dataIndex].match_review_start)
          )
          this.$set(
            this.dataForm,
            'match_review_end',
            new Date(res.data.data[dataIndex].match_review_end)
          )
        }
        this.dataForm.sessions = res.data.data[dataIndex].sessions
        this.dataForm.start_time_stage =
          res.data.data[dataIndex].start_time_stage
      }
      this.Content_tab = res.data.data
      console.log('🚀 ~ get_stage_list ~ this.Content_tab:', this.Content_tab)

    },
    // 深拷贝
    deepClone(obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }
      const clone = Object.assign({}, obj)
      Object.keys(clone).forEach((key) => {
        clone[key] = this.deepClone(clone[key])
      })
      return clone
    },
    //结算
     Onsettlement() {
       this.$confirm('确定要进行结算吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async() => {
        //判断_id,为空就提示先保存
      let _id = ''
      if (
        this.activeWeek - 1 < this.Content_tab.length &&
        this.Content_tab[this.activeWeek - 1]
      ) {
        _id = this.Content_tab[this.activeWeek - 1]._id
        //id不为空
        const { code } = await this.$http.match.s4_settle_stage({
          match_id: this.$route.query.match_id,
          _id: _id,
        })
        if (code === 0) {
          this.$message({
            type: 'success',
            message: '结算成功',
          })
           this.get_stage_list()
          this.settlement = false
        
        }
      } else {
        this.$message({
          message: '请先保存',
          type: 'warning',
        })
        return
      }
      }).catch(() => {
        // 用户点击了取消或关闭了弹窗
      });
      
     
    },
    //新增周
    Onaddweek() {
      if (this.type === 'regular') {
        let time = this.Content_tab[this.activeWeek - 1].status
        if (time === 2) {
          this.showDialogcoherence = true
        } else {
          this.$message.error('当前周未结算')
        }
      } else {
        let time = this.Content_tab[this.activeWeek - 1].status
        if (time === 2) {
          this.showDialog = true
        } else {
          this.$message.error('当前场次未结算')
        }
      }
    },
    //删除周
    DeleteWeek() {
      this.centerDialogVisible = true
    },
    //删除周确定按钮
    async centerDialogVisibleadd() {
      const { code } = await this.$http.match.s4_delete_stage({
        match_id: this.$route.query.match_id,
        season_id: this.$route.query._id,
        stage: this.activeWeek,
      })
      if (code === 0) {
        this.$message({
          type: 'success',
          message: '删除成功',
        })
        if (this.activeWeek > 1) {
          if (this.type === 'regular') {
            this.weeks_regular.splice(this.activeWeek - 1, 1)
            this.activeWeek = '1'
          } else if (this.type === 'qualifier') {
            this.weeks.splice(this.activeWeek - 1, 1)
            this.activeWeek = '1'
          }
        }
        this.centerDialogVisible = false
        this.get_stage_list()
      }
    },
    //预选赛弹框保存
    saveWeek() {
      this.$refs.addweek.validate((valid) => {
        if (valid) {
          let type = this.addweek.group_type
          let num = this.addweek.label
          if (Number(num) === 1) {
            this.$message.warning('已有相应的周')
            return
          }
          if (this.weeks.some((item) => item.label === num)) {
            this.$message.warning('已有相应的周')
            return true
          }
          let addweekarr = this.deepClone(this.addweek)
          addweekarr.name = this.weeks.length + 1
          this.$refs.addweek.resetFields()
          this.weeks.push(addweekarr)
          let activeWeek = this.weeks.length
          this.activeWeek = String(activeWeek)
          this.$set(this.dataForm, 'group_type', type)
          this.dataForm.sessions = []
          this.dataForm.start_time_stage = ''
          this.dataForm.match_review_start = ''
           this.dataForm.match_review_end = ''
          this.dataForm.duration = ''
          // console.log(this.dataForm.group_type);
          this.showDialog = false
        }
      })
    },
    //常规赛弹框保存
    onAddCoherence() {

      this.$refs.addcoherence.validate((valid) => {
        if (valid) {
          let type = this.addcoherence.group_type
          console.log(
            '🚀 ~ this.$refs.addcoherence.validate ~ this.addcoherence.group_type:',
            this.addcoherence.group_type
          )
          let addweekarr = this.deepClone(this.addcoherence)
          let num = this.addcoherence.label
          if (this.weeks_regular.some((item) => item.label === num)) {
            this.$message.warning('已有相应的轮次')
            return true
          }
          addweekarr.name = this.weeks_regular.length + 1
          this.$refs.addcoherence.resetFields()
          console.log(1111,addweekarr)
          this.weeks_regular.push(addweekarr)
          //
          let activeWeek = this.weeks_regular.length
          this.activeWeek = String(activeWeek)
          this.$set(this.dataForm, 'group_type', type)
          this.dataForm.match_review_end = ''
           this.dataForm.match_review_start = ''
          this.dataForm.sessions = []
          this.dataForm.start_time_stage = []
          this.showDialogcoherence = false
        }
      })
    },
    //保存(周/场次)
     save() {
         this.$confirm('确定要进行保存吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async() => {
        
      if (this.dataForm.duration == 0) {
        this.$message({
          type: 'warning',
          message: '每场比赛持续时间不能为0',
        })
        return
      }
       if (this.dataForm.duration === '') {
        this.$message({
          type: 'warning',
          message: '每场比赛持续时间不能为空',
        })
        return
      }
      if (this.dataForm.group_type === '') {
        this.$message({
          type: 'warning',
          message: '请选择分组类型',
        })
        return
      }
      if (
        this.dataForm.match_review_start === '' ||
        this.dataForm.match_review_end === ''
      ) {
        this.$message({
          type: 'warning',
          message: '请选择赛前分组时间',
        })
        return
      }
      let data = this.deepClone(this.dataForm)
      // 遍历sessions对象
      Object.keys(data.sessions).forEach((sessionKey) => {
        let session = data.sessions[sessionKey]
        // 遍历match_detail对象
        Object.keys(session.match_detail).forEach((detailKey) => {
          let match_detail = session.match_detail[detailKey]
          // 删除group_ids1和group_ids2属性
          delete match_detail.group_ids1
          delete match_detail.group_ids2
        })
      })
      const transformedObject = {
        ...data,
        sessions: Object.values(data.sessions).map((session) => ({
          ...session,
          match_detail: Object.values(session.match_detail),
        })),
        start_time_stage: data.start_time_stage,
      }

      let name = ''
      if (this.type === 'regular') {
        name = this.stage_name_regular
      } else {
        name = this.stage_name
      }
      let { code } = await this.$http.match.s4_save_stage({
        stage: this.activeWeek,
        stage_name: name,
        group_type: Number(this.dataForm.group_type),
        match_id: this.$route.query.match_id,
        sessions: transformedObject.sessions,
        start_time_stage: this.dataForm.start_time_stage,
        duration: this.dataForm.duration,
        season_id: this.$route.query._id,
        match_review_start: this.dataForm.match_review_start,
        match_review_end: this.dataForm.match_review_end,
      })
      if (code === 0) {
        this.$message({
          type: 'success',
          message: '保存成功',
        })
        this.get_stage_list()
       
      }
      }).catch(() => {
        // 用户点击了取消或关闭了弹窗
      });
    
    },
    // tab切换
    handleClick(tab) {
      this.get_stage_list()
    },
    //提交时
    transformSessions(sessions) {
      return sessions.map((session) => ({
        ...session,
        match_detail: session.match_detail.map((detail) => ({
          pattern: detail.pattern,
          start_time: detail.start_time,
          time_num: detail.time_num,
          group_ids: [detail.group_ids1, detail.group_ids2],
        })),
      }))
    },
    //回显
    transformSessions2(sessions) {
      if (sessions && sessions.length > 0) {
        return sessions.map((session) => ({
          ...session,
          match_detail: session.match_detail.map((detail) => ({
            pattern: detail.pattern,
            start_time: detail.start_time,
            time_num: detail.time_num,
            group_ids1: detail.group_ids[0],
            group_ids2: detail.group_ids[1],
          })),
        }))
      }
    },
    // 获取对战组
    async GetbattleList() {
      let res = await this.$http.match_subject.new_match_season_all({
        match_id: this.$route.query.match_id,
        season_id: this.$route.query._id,
      })
      this.OppositeStationGroup = res.data
    },
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format('yyyy/MM/dd hh:mm:ss')
      } else {
        return cellValue
      }
    },
    //选择场次生成时间
    stichArrayTimes() {
      //当场次生成时间为空时，场次生成日期置空
      if (!this.dataForm.start_time_stage) {
        this.dataForm.start_time_stage = []
        this.dataForm.sessions = []
      } else {
        //存在场次生成日期的时候
        var new_tempNum = 1
        let new_session_dates = []
        //追加日期时拿到已有的数据日期
        if (this.dataForm.sessions) {
          // 检查this.dataForm.sessions是否已定义
          this.dataForm.sessions.forEach((itemOne) => {
            new_session_dates.push(itemOne.start_date)
          })
        } else {
          this.dataForm.sessions = [] // 如果未定义，将其设置为一个空数组
        }

        //之前的将场次生成日期转化为数组日期
        let session_all_dates = []

        this.dataForm.start_time_stage.forEach((itemOne) => {
          //日期为 场次生成时间 字符串形式
          //如果没找到，则去掉this.dataForm.sessions的这个
          let temp = this.switchDateTime(itemOne)
          session_all_dates.push(temp)
          if (new_session_dates.indexOf(temp) == -1) {
            //将 新增的追加进去
            this.dataForm.sessions.push({
              start_date: temp,
              dates_num: 1,
              match_detail: [
                {
                  pattern: '',
                  start_time: '',
                  time_num: 0,
                  group_ids1: '',
                  group_ids2: '',
                },
              ],
            })
          }
          //未找到场次日期，则需要去掉
        })

        //拿到之前的场次生成七日
        new_session_dates.forEach((item) => {
          //目前的场次生成时间
          if (session_all_dates.indexOf(item) == -1) {
            this.dataForm.sessions.forEach((itemOne, idx) => {
              console.log('4434343f,日期相等', item, itemOne)
              if (item == itemOne.start_date) {
                this.dataForm.sessions.splice(idx, 1)
              }
            })
          }
        })
      }

      //更新数字
      this.switch_time_stage.forEach((itemOne, idx) => {
        itemOne.match_detail.forEach((one) => {
          one.time_num = new_tempNum
          new_tempNum++
        })
      })
      // }

      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },

    getDetailNum(item, num, details) {
      let newNum = num - details.length

      //说明增加
      if (details.length < num) {
        for (var i = 0; i < newNum; i++) {
          item.match_detail.push({
            pattern: '',
            start_time: '',
            group_ids1: '',
            group_ids2: '',
          })
        }
      } else {
        let reduceNum = details.length - num
        // 减少
        item.match_detail.splice(num, reduceNum)
      }

      var tempNum = 1
      this.switch_time_stage.forEach((itemOne, idx) => {
        itemOne.match_detail.forEach((one) => {
          one.time_num = tempNum
          tempNum++
        })
      })
    },
    // 添加初始化
    add() {
      this.view_type = 'add'
      this.bannerVisible = true
      this.has_stage = false
      this.initData()
    },
    switchDateTime(row) {
      var date = new Date(row) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-'
      let M =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1) + '-'
          : date.getMonth() + 1 + '-'
      let D =
        date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '

      return Y + M + D
    },
    //编辑赋值
    async handleDetail() {
      let res = await this.$http.match_subject.new_config_session({
        match_id: this.$route.query.match_id,
        season_id: this.$route.query._id,
      })
      ;(this.dataForm = res.data),
        (this.dataForm = {
          ...this.dataForm,
          sessions: this.transformSessions2(this.dataForm.sessions),
        })
      this.setFormData()
    },
    setFormData() {
      //如果没拿到数据。则代表为新建
      if (
        JSON.stringify(this.dataForm) != '{}' &&
        JSON.stringify(this.dataForm) != undefined
      ) {
        var temp_init_num = 1
        if (this.dataForm.sessions && this.dataForm.sessions.length != 0) {
          this.dataForm.sessions.forEach((item) => {
            item.match_detail.forEach((one) => {
              one.time_num = temp_init_num
              temp_init_num++
            })
          })
        }
      } else {
        this.initData()
      }
    },
    //查询列表
    async handleQuery() {
      this.handleDetail()
    },
    //废弃
    initData() {
      this.dataForm = {
        sessions: [],
        start_time_stage: [],
        _id: '',
        match_id: '',
        duration: '',
      }
    },
    //返回
    goBack() {
      this.$router.push({
        path: `/match/subject/list`,
        query: { match_id: this.match_id, match_type: this.match_type },
      })
    },
    // 提交
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          this.dataForm.match_id = this.match_id
          this.dataForm.season_id = this.season_id

          let d = {
            ...this.dataForm,
            sessions: this.transformSessions(this.dataForm.sessions),
          }
          console.log(d)
          d.duration = Number(d.duration)
          let { code } = await this.$http.match_subject.new_save_match_season(d)
          if (code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
            })

            this.$router.push({
              path: `/match/subject/list`,
              query: { match_id: this.match_id },
            })
          }
        } else {
          console.log('error submit!!')
          this.bannerVisible = true
        }
      })
    },
    resetForm() {
      this.$refs.dataForm.resetFields()
    },
    //tab的变化
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
  async mounted() {
    this.type = this.$route.query.type_id
    this.pageName = this.$route.query.season_name
    this.dataForm.season_name = '生成场次·' + this.$route.query.season_name
    this.match_id = this.$route.query.match_id
    this.match_type = this.$route.query.match_type
    this.season_id = this.$route.query._id

    // 获取对战组
    if (this.type != 'qualifier' && this.type != 'regular') {
      this.GetbattleList()
      this.handleQuery()
    }
    if (this.type === 'qualifier' || this.type === 'regular') {
      
      //查询列表
      this.handleQuery()
      this.get_stage_list()
    }
  },
}
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
.xunhuan {
  display: flex;
  flex-wrap: wrap; /* 允许元素在需要的时候换行 */
  padding-right: 200px;
}
.form-row {
  display: flex;
  justify-content: space-between;
}
.centeer {
  margin: auto;
  width: 340px;
}
.addweek {
  .addname {
    margin-left: 25px;
  }
}
.box_top {
  position: relative;
  .button_type {
    position: absolute;
    top: -10px;
    left: 27rem;
    width: 80px;
    text-align: center !important;
    width: 100px;
  }
}
.button_type2 {
  position: absolute;
  top: 0px;
  left: 14.5rem;
  width: 80px;
  text-align: center !important;
  width: 100px;
}
.buton_type {
  width: 80px;
}
.buton_type2 {
  width: 900px;
 display: flex;
}
</style>
